<template>
  <div class="admin-settings-trnaslations">
    <b-card class="cms-pages-admin" :title="$t('Subscriptions')">
      <b-row class="d-flex justify-content-end mb-2">
        <b-button :to="{path: `/admin/subscription-packages/add/${type}`}" variant="primary" size="sm">Add New Package</b-button>
      </b-row>
      <b-row>
        <b-col cols="12">
          <b-table responsive="sm" :items="items" :fields="fields" striped bordered class="text-center">
            <template #cell(status)="{item}">
              <b-form-checkbox
                :checked="item.status === 1"
                name="check-button"
                switch
                @change="updatePackage($event, item.uuid)"
              />
            </template>
            <template #cell(actions)="{item}">
              <b-button :to="{name: 'admin-subscription-packages-update', params: {package_for: type, uuid: item.uuid}}" variant="info" size="sm" class="mr-1">Edit</b-button>
              <b-button @click="deletePackage(item)" variant="danger" size="sm">Delete</b-button>
            </template>
          </b-table>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>
  
<script>
import { BCard, BRow, BCol, BButton, BTabs, BTab, BCardText, BTable, BFormCheckbox  } from 'bootstrap-vue'
import { hideLoader, showErrorNotification, showLoader } from '@/@core/comp-functions/ui/app'
import { mapActions, mapGetters } from 'vuex'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BTabs, 
    BTab, 
    BCardText,
    BTable,
    BFormCheckbox,
  },
  data: () => ({
  }),
  computed: {
    ...mapGetters('subscriptionPlan', [
      'allStoreSubscriptions',
      'allProviderSubscriptions',
      'allUserSubscriptions',
      'isSubscriptionLoaded',
    ]),
    type: {
      get(){
        if(this.$route.name == 'admin-subscription-packages-provider') return 'provider'
        if(this.$route.name == 'admin-subscription-packages-store') return 'store'
        if(this.$route.name == 'admin-subscription-packages-user') return 'user'
        return null
      }
    },
    items: {
      get(){
        if(this.$route.name == 'admin-subscription-packages-provider') return this.allProviderSubscriptions
        if(this.$route.name == 'admin-subscription-packages-store') return this.allStoreSubscriptions
        if(this.$route.name == 'admin-subscription-packages-user') return this.allUserSubscriptions
        return []
      }
    },
    fields: {
      get(){
        let fields = [
          {
            key: 'package_name',
            label: 'Name',
            sortable: true
          },
          {
            key: 'package_amount',
            label: 'Amount',
            sortable: true
          },
          {
            key: 'tenure',
            label: 'Tenure',
            sortable: true
          },
          {
            key: 'tenure_type',
            label: 'Tenure Type',
            sortable: true
          },
        ]
        if(this.type == 'user'){
          fields = fields.concat([
            {
              key: 'allowed_words',
              label: 'Allowed Words',
              sortable: true
            },
            {
              key: 'allowed_images',
              label: 'Allowed Images',
              sortable: true
            },
            {
              key: 'allowed_s2t',
              label: 'Allowed Speech to Text',
              sortable: true
            },
          ])
        }else{
          fields = fields.concat([
            {
              key: 'allowed_orders',
              label: 'Allowed Orders',
              sortable: true
            },
            {
              key: 'exceed_amount_per_order',
              label: 'Exceed Amount',
              sortable: true
            },
          ])
        }
        fields = fields.concat([
          {
            key: 'status',
            label: 'Status',
            sortable: true
          },
          {
            key: 'actions',
            label: 'Actions',
            sortable: true
          },
        ])
        return fields
      }
    }
  },
  mounted() {
    if(!this.isSubscriptionLoaded){
      showLoader()
      this.getSubscriptionPackages()
        .then(res => {
          hideLoader()
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    }
  },
  methods: {
    ...mapActions('subscriptionPlan', ['getSubscriptionPackages', 'updateSubscriptionPackage', 'deleteSubscriptionPackage']),
    async updatePackage(status, uuid){
      showLoader()
      const formData = new FormData
      formData.append('uuid', uuid)
      formData.append('status', status ? 1 : 0)
      await this.updateSubscriptionPackage(formData)
      hideLoader()
    },
    async deletePackage(item){
      if(confirm("Are you sure you want to delete this package?")){
        showLoader()
        await this.deleteSubscriptionPackage(item.uuid)
        hideLoader()
      }
    },
  },
}
</script>
  